import nightOwl from "@theme-ui/prism/presets/night-owl.json"
import { base as defaultTheme } from "@theme-ui/presets"
import merge from "lodash.merge"

export default merge(defaultTheme, {
  fonts: {
    body: "system-ui, sans-serif",
    heading: "inherit",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  colors: {
    primary: "#4b5e0c",
    background: "#fff",
    heading: "#414042",
    text: "#666",
    gdntStart: "#246bad",
    gdntEnd: "#3b4c00",
    muted: "#f2f2f2",
    secondaryBackground: "#f6f8f9", // used in footer
  },
  styles: {
    code: {
      ...nightOwl,
    },
  },
  buttons: {
    primary: {
      color: "white",
      bg: "primary",
    },
    secondary: {
      color: "white",
      bg: "secondary",
    },
    outline: {
      bg: "background",
      color: "primary",
      border: "1px solid",
      borderColor: "primary",
    },
    transparent: {
      bg: "transparent",
      color: "text",
      ":hover": {
        color: "primary",
      },
    },
  },
})
