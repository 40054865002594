module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"enableIdentityWidget":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Benchmark Official website","short_name":"Benchmark","description":"Benchmark Digital Solutions is a national company has been established to Reflect UAE future vision in transforming services and business flow from traditional form to electronic and smart form.  Mobile App development - Web Site development - Mobile Game development","lang":"en","display":"minimal-ui","start_url":"/","background_color":"#7e9822","theme_color":"#7e9822","icon":"src/images/favicon.png","localize":[{"start_url":"/ar/","lang":"ar","name":"الموقع الرسمي لمؤسسة بنشمارك","short_name":"بنشمارك","description":"بنشمارك للحلول الرقمية هي شركة وطنية تم إنشاؤها لتعكس الرؤية المستقبلية لدولة الإمارات في\n            تحويل الخدمات وتدفق الأعمال من الشكل التقليدي إلى الشكل الإلكتروني والذكي. | تطوير تطبيقات الهاتف المحمول - تطوير مواقع الويب - تطوير ألعاب الجوال"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b57ab8b18922132030a513e180d8757c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"981327306133682"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-206539459-1","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/admin/**"],"pageTransitionDelay":0,"defer":false,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"benchmark.ae","enableWebVitalsTracking":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
